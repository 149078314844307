/* src/App.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root, .App {
  height: 100%;
  margin: 0;
  background-color: #191919;
}

html {
  scroll-behavior: smooth;
}

.App {
  overflow-y: auto; /* Changed from scroll to auto for better behavior */
}

#home {
  height: 100vh;
}

.section {
  /* Removed fixed height */
  padding-top: 80px; /* Adjust based on navbar height */
}

@media (min-width: 768px) {
  .section {
    padding-top: 80px; /* Ensure consistent padding across devices */
  }
}

/* Custom Fade-In Animation */
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fade-in 1s ease-out forwards;
}
